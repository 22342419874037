import React from 'react'
import Layout from '../containers/Layout/Layout'
import tw, { styled } from 'twin.macro'
import Seo from '../components/Seo'
import { Link } from 'gatsby'
import BreadCrumbArrow from '../assets/svgs/breadcrumb-arrow.svg'
import PageCover from '../components/PageCover'

import GalleryBackgroundImage from '../assets/images/gallery-cover.png'
import AumaImage from '../assets/images/auma-exhibition-image.jpg'
import CosascoFieldWork from '../assets/images/cosasco-field-work.jpg'
import CosascoTraining from '../assets/images/cosasco-training.jpg'
import FieldWork from '../assets/images/field-work.jpg'
import ManzExhibition from '../assets/images/manz-exhibition.jpg'
import NOG from '../assets/images/nog.jpg'

const Container = styled.section`
  max-width: 1376px;
  ${tw`mb-12 mx-auto px-5 pt-10 md:pt-20 grid grid-cols-1 sm:gap-x-6 sm:gap-y-12 sm:grid-cols-2 lg:grid-cols-3`};
`

const GalleryLink = styled(props => <Link {...props} />)`
  transition: all .5s;

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transform: scale(1.02);
  }
`

const GalleryItem = tw.div`pb-4`;

const GalleryItemLink = styled.a`
  ${tw`block mx-auto capitalize font-mulish font-extrabold tracking-brand text-center`}
  font-size: 30px;
  line-height: 38px;
`;


const GalleryImage = styled.img`
  ${tw`w-full h-auto object-cover`};
  height: 334px;
  margin-bottom: 19px;
`;

const Gallery = () => {
  
  const galleryItems = [
    {
      id: 1,
      imgSrc: AumaImage,
      title: 'Auma Training',
      link: 'auma-training'
    },
    {
      id: 2,
      imgSrc: CosascoFieldWork,
      title: 'Cosasco Field Work',
      link: 'cosasco-field-work'
    },
    {
      id: 3,
      imgSrc: CosascoTraining,
      title: 'Cosasco Training',
      link: 'cosasco-training'
    },
    {
      id: 4,
      imgSrc: FieldWork,
      title: 'Field Work',
      link: 'field-work'
    },
    {
      id: 5,
      imgSrc: ManzExhibition,
      title: 'OEM Exhibitions',
      link: 'oem-exhibitions'
    },
    {
      id: 6,
      imgSrc: NOG,
      title: 'NOG Conference',
      link: 'nog-conference'
    },
  ]

  return (
    <Layout>
      <Seo title="Gallery" />
      <PageCover
        imageSrc={GalleryBackgroundImage}
        coverTitle="Gallery"
      >
        <Link to="/">Home</Link>
        <div>
          <BreadCrumbArrow />
        </div>
        <Link to="/gallery">Gallery</Link>
      </PageCover>
      <Container>
        {
          galleryItems.map(galleryItem => {
            return (
              <GalleryLink key={galleryItem.id} to={galleryItem.link}>
                <GalleryItem to={galleryItem.link}>
                  <GalleryImage src={galleryItem.imgSrc} />
                  <GalleryItemLink>{galleryItem.title}</GalleryItemLink>
                </GalleryItem>
              </GalleryLink>
            )
          })
        }
      </Container>
    </Layout>
  )
}

export default Gallery